import React from 'react';
import PropTypes from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import HeaderSearchComponent from '../../components/find-your-property/HeaderSearchComponent';
import ProjectPropertiesComponent from '../../components/home-page/ProjectPropertiesComponent';
import ListYourPropertyComponent from '../../components/home-page/ListYourPropertyComponent';
import ClientsPartialListComponent from '../../components/home-page/ClientsPartialListComponent';
import BusinessDistrictComponent from '../../components/home-page/BusinessDistrictComponent';
import { sanitizePropertyName, slugify } from '../../utilities/filter-listings';

function FindYourPropertyIndexPage({ location }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiProjectListing(
        sort: { fields: priority, order: ASC }
        filter: { visibility: { eq: true } }
      ) {
        nodes {
          status
          banner
          strapiId
          thumb
          url_link
          visibility
          propertyname
          category
          classification
          location
        }
      }

      allPropertiesCommercialLots {
        nodes {
          id
          electrical_capacity
          electric_meter
          property_name
          authority_type
          price_per_sqm
          selling_price
          lot_area
          floor_area
          area_scope
          availability
          city
          classification
          fibre_optic_capable
          floor_area_float_val
          handover_condition
          generator_backup_power
          latitude
          level
          listing_code
          listing_id
          listing_image {
            data {
              image_path
              thumb_path
            }
          }
          location
          longitude
          lot_area_float_val
          peza_accredited
          parking_rate
          price_per_sqm_float_val
          property_type
          telecommunications_provider
          tenant_preferences
          tenant_restrictions
          total_parking_slots
        }
      }

      allPropertiesOfficeProjects(
        sort: { fields: created_at___date, order: DESC }
      ) {
        nodes {
          project_name
          property_type
          authority_type
          address
          city
          available_units
          building_grade
          designation
          date_completion
          handover_condition
          latitude
          longitude
          lot_area
          no_escalator
          no_of_basement
          no_of_storey
          no_passenger_elevator
          operating_hours
          project_id
          project_code
          tenant_preferences
          tenant_restrictions
          created_at {
            date(formatString: "MMM DD, YYYY")
          }
          project_image {
            data {
              image_path
              thumb_path
              image_id
            }
          }
        }
      }
    }
  `);

  function authorityTypePosition(authorityType) {
    switch (authorityType) {
      case 'Exclusive':
        return 0;
      case 'Sole Agency':
        return 1;
      case 'Lead Broker':
        return 2;
      case 'Non-exclusive':
        return 3;
      case 'Open':
        return 4;
      case 'Soft':
        return 5;
      default:
        return 6;
    }
  }

  function propertiesDataArr(
    cityName,
    objKey = 'allPropertiesOfficeProjects',
    category = 'OFFICE',
    idKey = 'project_id',
    imgKey = 'project_image',
    listingKey = 'project_name'
  ) {
    return data[objKey].nodes
      .filter(node => node[listingKey] !== undefined) // remove nodes without listing key
      .filter(
        node =>
          `${node.location || node.address}, ${node.city}`
            .toLowerCase()
            .includes(cityName.toLowerCase()) // filter nodes by submitted city name
      )
      .map(node => ({
        // prepare authority numbering for sorting
        ...node,
        authorityPosition: authorityTypePosition(node.authority_type),
        authority_type: node.authority_type,
      }))
      .map(node => ({
        status: '',
        strapiId: node[idKey],
        banner:
          node[imgKey].data !== undefined || node[imgKey].data !== null
            ? node[imgKey].data.length - 1 > 0
              ? `${process.env.IMAGES_URL_PATH}/${node[imgKey].data[0].thumb_path}`
              : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
            : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg',
        thumb:
          node[imgKey].data !== undefined || node[imgKey].data !== null
            ? node[imgKey].data.length - 1 > 0
              ? `${process.env.IMAGES_URL_PATH}/${node[imgKey].data[0].thumb_path}`
              : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
            : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg',
        url_link: `${slugify(sanitizePropertyName(node[listingKey]))}-${
          node[idKey]
        }`,
        visibility: 1,
        propertyname: sanitizePropertyName(node[listingKey]),
        category,
        classification: 'For Lease',
        location: `${node.location || node.address}${
          node.city ? `, ${node.city}` : ''
        }`,
        // authorityPosition: authorityTypePosition(node.authority_type), assuming this was just duplicated
        // authority_type: node.authority_type,
      }))
      .sort(() => Math.random() - 0.5)
      .slice(0, 7);
  }

  return (
    <Layout source="Find Your Property">
      <SEO
        title="Find Your Property"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'office space for lease, retail space for lease, industrial space for lease, ',
          },
        ]}
      />
      <div>
        <HeaderSearchComponent />
        <ProjectPropertiesComponent
          title={
            <h2>
              Featured <span>Office Spaces</span> for lease in{' '}
              <span>Quezon City</span>
            </h2>
          }
          description={
            <p>
              Looking to setup your first office? Ease into an easier travel to
              work with these spaces located at center of Metro Manila.
            </p>
          }
          properties={propertiesDataArr('Quezon City').filter(
            keyProject => keyProject.category === 'OFFICE'
          )}
          internalLink={{
            url: `/find-a-property/search?location=Quezon City&property-type=Office`,
            label: (
              <span>
                VIEW OTHER QUEZON CITY OFFICE SPACES{'  '}
                <FontAwesomeIcon icon="angle-right" />
              </span>
            ),
          }}
          padding="50px 0px"
        />

        <ListYourPropertyComponent />

        <ProjectPropertiesComponent
          title={
            <h2>
              Featured <span>Office Spaces</span> in <span>Makati City</span>
            </h2>
          }
          description={
            <p>
              These projects at the heart of Makati will get your business the
              best value.
            </p>
          }
          properties={propertiesDataArr('Makati').filter(
            keyProject => keyProject.category === 'OFFICE'
          )}
          internalLink={{
            url: `/find-a-property/search?location=Makati&property-type=Office`,
            label: (
              <span>
                VIEW OTHER MAKATI CITY OFFICE SPACES{'  '}
                <FontAwesomeIcon icon="angle-right" />
              </span>
            ),
          }}
          padding="50px 0px"
        />

        <ProjectPropertiesComponent
          title={
            <h2>
              Featured <span>Commercial Lots</span> in <span>Davao City</span>
            </h2>
          }
          description={
            <p>
              {`From commercial spaces to commercial lots, ranging from big to
              small, there's a PRIME property that can accomodate new and
              existing businesses.`}
            </p>
          }
          properties={propertiesDataArr(
            'Davao',
            'allPropertiesCommercialLots',
            'INDUSTRIAL',
            'listing_id',
            'listing_image',
            'property_name'
          ).filter(keyProject => keyProject.category === 'INDUSTRIAL')}
          internalLink={{
            url: `/find-a-property/search?location=Davao&property-type=Retail&development-type=Commercial Lots`,
            label: (
              <span>
                VIEW OTHER DAVAO CITY COMMERCIAL LOTS{'  '}
                <FontAwesomeIcon icon="angle-right" />
              </span>
            ),
          }}
          padding="50px 0px"
        />

        <BusinessDistrictComponent
          title={
            <h2>
              <span>Business District</span> in the Philippines
            </h2>
          }
          description={
            <p>
              {`Get a headstart in the Philippines' financial capital and secure
              your competitive edge with these office spaces.`}
            </p>
          }
          padding="50px 0px"
        />

        <ClientsPartialListComponent />
      </div>
    </Layout>
  );
}

FindYourPropertyIndexPage.propTypes = {
  location: PropTypes.any,
};
export default FindYourPropertyIndexPage;
