import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import PropTypes from 'prop-types';

const StyledDivWrapper = styled.div`
  background-color: ${props => `${props.theme.coldBluePalette.light}`};
  padding: ${props => `${props.padding}`};

  > div {
    max-width: 1440px;
    margin: auto;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin-bottom: 0;
    }
    h2 {
      margin-bottom: 6px;
      span {
        font-family: Poppins, sans-serif;
        color: ${props => `${props.theme.primeBluePalette.original}`};
      }
    }
  }
`;

const PropertyCard = styled(Link)`
  display: flex;
  flex-direction: column;

  min-height: ${props => props.cardminheight};
  background-color: ${props => `${props.theme.blackPalette.light}`};
  color: ${props => `${props.theme.whitePalette.original}`};
  :hover {
    color: ${props => `${props.theme.whitePalette.original}AA`};
  }

  > div > div {
    z-index: 1;
    line-height: 1;
    > div:first-child {
      font-weight: bold;
    }
    > div:last-child {
      font-size: 80%;
    }
  }

  > div {
    text-align: center;
    flex: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${props => `url('${props.thumbnail}')`};

    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  position: relative;

  ::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${props => `${props.theme.blackPalette.original}99`};
  }

  .status {
    position: absolute;
    top: 10px;
    right: 0px;
    background-color: ${props => `${props.theme.primeBluePalette.light}`};
    padding: 6px 12px;
    font-size: 12px;
    font-weight: bold;
  }
`;

function BusinessDistrictComponent(props) {
  const data = useStaticQuery(graphql`
    {
      allStrapiBusinessDistrict(sort: { order: ASC, fields: district_name }) {
        nodes {
          district_name
          sub_name
          strapiId
          slug
          thumbnail_photo
          id
          banner_photo
          content
          meta_keywords
          meta_description
          featured_flag
          cmci_ranking
          land_area
          latitude
          longitude
          population
          total_office_supply
        }
      }
    }
  `);

  const highlightCity = data.allStrapiBusinessDistrict.nodes.filter(
    district => district.featured_flag
  )[0];
  const businessCities = data.allStrapiBusinessDistrict.nodes.filter(
    district => !district.featured_flag
  );

  return (
    <StyledDivWrapper padding={props.padding}>
      <Row type="flex" justify="center">
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <Row gutter={[10, 10]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="title-wrapper"
            >
              {props.title}
              {props.description}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <PropertyCard
                className="highlighted-city"
                to={`/business-districts/${highlightCity.slug}`}
                thumbnail={highlightCity.thumbnail_photo}
                cardminheight="150px"
                style={{ height: '100%' }}
              >
                <div>
                  <div>
                    <div style={{ fontSize: '2em' }}>
                      {highlightCity.district_name}
                    </div>
                    <div>{highlightCity.sub_name}</div>
                  </div>
                </div>
              </PropertyCard>
            </Col>
          </Row>

          <Row gutter={[10, 10]}>
            {businessCities.map(property => {
              return (
                <Col
                  key={property.strapiId}
                  xs={24}
                  sm={24}
                  md={8}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <PropertyCard
                    to={`/business-districts/${property.slug}`}
                    className="property-card"
                    thumbnail={property.thumbnail_photo}
                    cardminheight="100px"
                  >
                    <div>
                      <div>
                        <div style={{ fontSize: '1.3em' }}>
                          {property.district_name}
                        </div>
                        <div>{property.sub_name}</div>
                      </div>
                    </div>
                  </PropertyCard>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}
BusinessDistrictComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  padding: PropTypes.string,
};

BusinessDistrictComponent.defaultProps = {
  padding: '50px 0px',
};

export default BusinessDistrictComponent;
