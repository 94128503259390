/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import Slider from 'react-slick';
import { Input, Row, Col, Menu, Dropdown, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const { Search } = Input;

const StyledDivWrapper = styled.div`
  max-width: 1440px;
  padding-top: 89px;
  margin: 0px auto;

  h1 {
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.3rem;
    line-height: calc(1.3rem + 4px);
  }

  .header-cta {
    text-decoration: underline;
    font-weight: bold;
    > a {
      color: ${props => props.theme.whitePalette.original};
      :hover {
        opacity: 0.8;
      }
    }
  }

  .main-wrapper {
    position: relative;
    min-height: 450px;
    background-position: center;
    background-size: cover;
    ::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.25) 100%;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-wrapper {
    position: relative;
    z-index: 1;
    color: ${props => props.theme.whitePalette.original};
    text-align: center;
    h1,
    p {
      text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    }
  }

  .search-type {
    display: flex;
    justify-content: center;

    > div {
      padding: 6px 18px;
      font-weight: bold;
      color: ${props => props.theme.whitePalette.original};
    }
    > :nth-child(2),
    > :nth-child(3),
    > :nth-child(4) {
      background-color: ${props => props.theme.whitePalette.original};
      color: ${props => props.theme.blackPalette.original};

      :hover {
        cursor: pointer;
        background-color: ${props => props.theme.primeBluePalette.original};
        color: ${props => props.theme.primeYellowPalette.original};
      }
    }

    .active {
      background-color: ${props => props.theme.primeBluePalette.original};
      color: ${props => props.theme.primeYellowPalette.original};
    }
  }

  .search-wrapper {
    top: -60px;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    position: relative;
  }

  .ant-btn-lg {
    height: 64px;
    padding: 4.9px 15px;
    font-size: 24px;
  }

  .ant-input-lg {
    height: 64px;
    padding: 10px 14px;
    font-size: 1.5em;
  }

  .ant-btn.ant-dropdown-trigger {
    width: 100%;
    text-align: left;
  }

  .btn-menu {
    display: none;
    visibility: hidden;
  }

  @media only screen and (max-width: 576px) {
    .main-wrapper {
      min-height: 400px;
    }

    .btn-menu {
      display: flex;
      visibility: visible;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .search-wrapper {
      top: 0;
      max-width: initial;
      padding: 20px;
    }

    .search-type {
      flex-direction: column;

      > div {
        padding: 0px;
        font-weight: bold;
        color: inherit;
      }

      > :nth-child(2),
      > :nth-child(3),
      > :nth-child(4) {
        display: none;
        visibility: hidden;
      }
    }

    .ant-btn-lg,
    .ant-input-lg {
      height: 36px;
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    .search-wrapper {
      max-width: 95%;
    }

    .search-type {
      font-size: 0.8em;
      > :nth-child(5) {
        padding: 0;
      }
    }

    .ant-btn-lg,
    .ant-input-lg {
      height: 42px;
      font-size: 1.2rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .search-wrapper {
      max-width: 85%;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .search-type {
      font-size: 1.3em;
    }
    .search-wrapper {
      max-width: 85%;
    }
  }

  @media only screen and (min-width: 1600px) {
    .search-type {
      font-size: 1.3em;
    }
    .search-wrapper {
      max-width: 70%;
    }
  }

  @media only screen and (min-width: 768px) {
    .search-type {
      > :nth-child(5) {
        padding: 0;
      }
    }
  }
`;

function HeaderSearchComponent() {
  const slickRef = useRef(null);

  const settings = {
    dots: true,
    lazyLoad: true,
    arrows: false,
    fade: true,
    swipe: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [selectedPropertyType, setSelectedPropertyType] = useState(
    'OFFICE SPACES'
  );

  const searchBanner = [
    {
      banner: 'fap-office-bg.jpg',
      title: 'Find the perfect property for you.',
      description: `Let our team of expert leasing professionals help you
                take your business to the next level with our office
                spaces and projects accross multiple CBDs in
                Philippines.`,
    },
    {
      banner: 'fap-retail-bg.jpg',
      title: `Find the perfect property for you.`,
      description: `Let our team of expert leasing professionals help you
      take your business to the next level with our office
      spaces and projects accross multiple CBDs in
      Philippines.`,
    },
    {
      banner: 'fap-warehouse-bg.jpg',
      title: `Find the perfect property for you.`,
      description: `Let our team of expert leasing professionals help you
    take your business to the next level with our office
    spaces and projects accross multiple CBDs in
    Philippines.`,
    },
  ];

  function MenuOverlay() {
    return (
      <Menu>
        {[
          'OFFICE SPACES',
          'COMMERCIAL SPACES & LOTS',
          'INDUSTRIAL & WAREHOUSES',
        ].map((type, i) => (
          <Menu.Item
            key={type}
            onClick={() => {
              slickRef.current.slickGoTo(i);
              setSelectedPropertyType();
            }}
          >
            {type}
          </Menu.Item>
        ))}
      </Menu>
    );
  }
  return (
    <StyledDivWrapper>
      <div>
        <Slider {...settings} ref={slickRef}>
          {searchBanner.map(banner => (
            <div key={banner.title}>
              <div
                className="main-wrapper"
                style={{
                  backgroundImage: ` url('../../images/home/${banner.banner}')`,
                }}
              >
                <div className="text-wrapper">
                  <Row type="flex" justify="center" align="center">
                    <Col xs={21} sm={23} md={23} lg={20} xl={18} xxl={16}>
                      <h1>{banner.title}</h1>
                      <p>{banner.description}</p>
                      <p className="header-cta">
                        <Link to="/our-expertise/experienced/developer-and-landlord-brokerage-services">{`Review our leasing expertise here >`}</Link>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="search-wrapper">
        <div className="search-type">
          <div>Search For:</div>
          <div
            tabIndex="0"
            role="button"
            className={selectedPropertyType === 'OFFICE SPACES' ? 'active' : ''}
            onClick={() => {
              slickRef.current.slickGoTo(0);
              setSelectedPropertyType('OFFICE SPACES');
            }}
            onKeyDown={null}
          >
            OFFICE SPACES
          </div>
          <div
            className={
              selectedPropertyType === 'COMMERCIAL SPACES & LOTS'
                ? 'active'
                : ''
            }
            style={{
              borderRight: `1px solid #eee`,
              borderLeft: `1px solid #eee`,
            }}
            tabIndex="0"
            role="button"
            onClick={() => {
              slickRef.current.slickGoTo(1);
              setSelectedPropertyType('COMMERCIAL SPACES & LOTS');
            }}
            onKeyDown={null}
          >
            COMMERCIAL SPACES & LOTS
          </div>
          <div
            className={
              selectedPropertyType === 'INDUSTRIAL & WAREHOUSES' ? 'active' : ''
            }
            onClick={() => {
              slickRef.current.slickGoTo(2);
              setSelectedPropertyType('INDUSTRIAL & WAREHOUSES');
            }}
            tabIndex="0"
            role="button"
            onKeyDown={null}
          >
            INDUSTRIAL & WAREHOUSES
          </div>
          <div>
            <Dropdown
              overlay={MenuOverlay}
              placement="bottomLeft"
              trigger={['click', 'trigger']}
            >
              <Button className="btn-menu">
                OFFICE SPACES
                <FontAwesomeIcon icon="angle-down" size="1x" />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div>
          <Search
            placeholder="Search by location, description, name"
            onSearch={value => {
              let propertyTypeParams = 'Office';
              switch (selectedPropertyType) {
                case 'OFFICE SPACES':
                  propertyTypeParams = 'Office';
                  break;
                case 'COMMERCIAL SPACES & LOTS':
                  propertyTypeParams = 'Retail';
                  break;
                case 'INDUSTRIAL & WAREHOUSES':
                  propertyTypeParams = 'Industrial';
                  break;
                default:
                  propertyTypeParams = 'Office';
                  break;
              }
              navigate(
                `/find-a-property/search?location=${value}&property-type=${propertyTypeParams}&page=1`
              );
            }}
            size="large"
            enterButton={
              <div>
                <FontAwesomeIcon
                  icon="search"
                  size="1x"
                  fixedWidth
                  style={{ marginRight: 5 }}
                ></FontAwesomeIcon>
                SEARCH
              </div>
            }
          />
        </div>
      </div>
    </StyledDivWrapper>
  );
}

export default HeaderSearchComponent;
