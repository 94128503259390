import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const clientsLogo = [
  [
    {
      imgFile: 'client-sppp.png',
      alt: 'Sagisag ng Pangalawang Pangulo ng Pilipinas',
      span: {
        xs: 7,
        sm: 7,
        md: 3,
        lg: 3,
        xl: 3,
        xxl: 3,
      },
    },
    {
      imgFile: 'client-nyc.png',
      alt: 'National Youth Commission',
      span: {
        xs: 7,
        sm: 7,
        md: 3,
        lg: 3,
        xl: 3,
        xxl: 3,
      },
    },
    {
      imgFile: 'client-pms.png',
      alt: 'Presidential Management System',
      span: {
        xs: 7,
        sm: 7,
        md: 3,
        lg: 3,
        xl: 3,
        xxl: 3,
      },
    },
    {
      imgFile: 'client-starbucks.png',
      alt: 'Starbucks',
      span: {
        xs: 7,
        sm: 7,
        md: 3,
        lg: 3,
        xl: 3,
        xxl: 3,
      },
    },
    {
      imgFile: 'client-petron.png',
      alt: 'Petron',
      span: {
        xs: 7,
        sm: 7,
        md: 3,
        lg: 3,
        xl: 3,
        xxl: 3,
      },
    },
    {
      imgFile: 'client-bdo.png',
      alt: 'BDO',
      span: {
        xs: 7,
        sm: 7,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
    },
  ],

  [
    {
      imgFile: 'client-watsons.png',
      alt: 'Watsons',
      span: {
        xs: 10,
        sm: 10,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
    },
    {
      imgFile: 'client-fontrow.png',
      alt: 'Frontrow',
      span: {
        xs: 10,
        sm: 10,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
    },
    {
      imgFile: 'client-7-eleven.png',
      alt: '7-ELEVEn',
      span: {
        xs: 10,
        sm: 10,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
    },
    {
      imgFile: 'client-hi-precision.png',
      alt: 'Hi-Precision Diagnostics',
      span: {
        xs: 10,
        sm: 10,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
    },
    {
      imgFile: 'client-hikvision.png',
      alt: 'HIKVISION',
      span: {
        xs: 10,
        sm: 10,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
    },
  ],
];

const StyledComponentWrapper = styled.div`
  padding: 120px 0px;

  .section-title {
    position: relative;
    margin-bottom: 6px;

    span {
      font-weight: 600;
      font-family: Poppins, sans-sans-serif;
      color: ${props => props.theme.primeBluePalette.original};
    }
  }
  .section-description {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 48px;
    margin-top: 6px;
  }

  .client-logo {
    text-align: center;
    img {
      margin-bottom: 0px;
    }
  }
`;

function ClientsPartialListComponent() {
  return (
    <StyledComponentWrapper>
      <Row type="flex" justify="center">
        <Col
          xs={23}
          sm={23}
          md={16}
          lg={14}
          xl={10}
          xxl={10}
          style={{ textAlign: 'center' }}
        >
          <h2 className="section-title">
            Our <span>Partial List of Clients</span>
          </h2>
          <p className="section-description">
            Government and private companies, big or small, local or
            international, trust PRIME with their real estate needs.
          </p>
        </Col>
      </Row>

      <Row className="row-client-carousel" type="flex" justify="center">
        <Col xs={24} sm={23} md={23} lg={20} xl={20} xxl={20}>
          {clientsLogo.map((imgs, index) => (
            <Row
              key={`${imgs}${index}`}
              type="flex"
              justify="center"
              align="middle"
              gutter={[15, 30]}
            >
              {imgs.map(img => (
                <Col key={img.alt} {...img.span}>
                  <div className="client-logo" key={img.imgFile}>
                    <img
                      src={`../../images/home/clients-logo/alt-logo/${img.imgFile}`}
                      alt={img.alt}
                      style={{ maxHeight: 100 }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          ))}
        </Col>
      </Row>
    </StyledComponentWrapper>
  );
}

export default ClientsPartialListComponent;
