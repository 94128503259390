/* eslint-disable no-param-reassign */
import React, { createRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row, Col } from 'antd';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const kenburnsTop = keyframes`
    0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.15) translateY(-15px);
            transform: scale(1.15) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
`;

const StyledDivWrapper = styled.div`
  max-width: 1440px;
  margin: auto;

  padding: ${props => `${props.padding}`};
  .title-wrapper {
    h2 {
      margin-bottom: 6px;
      span {
        font-family: Poppins, sans-serif;
        color: ${props => `${props.theme.primeBluePalette.original}`};
      }
    }
  }
`;

const PropertyCard = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${props => `${props.theme.blackPalette.light}`};
  color: ${props => `${props.theme.whitePalette.original}`};
  overflow: hidden;
  position: relative;

  > div:last-child {
    position: absolute;
    width: 100%;
    bottom: 0;
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    z-index: 1;
    line-height: 1;
    > div:first-child {
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  .image-bg {
    min-height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  ::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 62%,
      rgba(0, 0, 0, 0.58) 100%
    );
  }

  .status {
    position: absolute;
    top: 10px;
    right: 0px;
    background-color: ${props => `${props.theme.primeBluePalette.light}`};
    padding: 6px 12px;
    font-size: 12px;
    font-weight: bold;
  }

  :hover {
    color: ${props => `${props.theme.whitePalette.original}DD`};
    .image-bg {
      animation: ${kenburnsTop} 3s ease-out both;
    }
  }
`;

const ExternalActionCard = styled(OutboundLink)`
  min-height: 250px;
  background-color: ${props => `${props.theme.blackPalette.light}`};

  display: flex;
  flex-direction: column;
  position: relative;

  font-size: 1.3em;
  font-weight: bold;
  color: ${props => `${props.theme.primeYellowPalette.original}`};
  div > div {
    z-index: 1;
    line-height: 1;
  }

  :hover {
    color: ${props => `${props.theme.primeYellowPalette.original}AA`};
  }

  > div {
    flex: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => `${props.theme.primeBluePalette.original}CC`};
  }
`;

const InternalActionCard = styled(Link)`
  min-height: 250px;
  background-color: ${props => `${props.theme.blackPalette.light}`};

  display: flex;
  flex-direction: column;
  position: relative;

  font-size: 1.3em;
  font-weight: bold;
  color: ${props => `${props.theme.primeYellowPalette.original}`};
  div > div {
    z-index: 1;
    line-height: 1;
  }

  :hover {
    color: ${props => `${props.theme.primeYellowPalette.original}AA`};
  }

  > div {
    flex: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => `${props.theme.primeBluePalette.original}CC`};
  }
`;

function ProjectPropertiesComponent(props) {
  const {
    padding,
    title,
    description,
    properties,
    externalLink,
    internalLink,
  } = props;

  const propertiesWithRef = properties.map(property => ({
    ...property,
    imgRef: createRef(),
    linkRef: createRef(),
  }));

  useEffect(() => {
    propertiesWithRef.forEach(property => {
      property.imgRef.current.style.backgroundImage = `url('${property.thumb}'), url('https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg')`;
      if (property.linkRef.current !== null)
        property.linkRef.current.href = `/listings/${property.url_link}`;
    });
  });

  return (
    <StyledDivWrapper padding={padding}>
      <Row type="flex" justify="center">
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={18}
              lg={18}
              xl={16}
              xxl={16}
              className="title-wrapper"
            >
              {title}
              {description || ''}
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            {propertiesWithRef.map(property => (
              <Col
                key={property.strapiId}
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={6}
                xxl={6}
              >
                <Link
                  to={`/listings/${property.url_link}`}
                  ref={property.linkRef}
                >
                  <PropertyCard>
                    <div className="image-bg" ref={property.imgRef}></div>
                    <div>
                      <div>{property.propertyname}</div>
                      <div>{property.location}</div>
                    </div>
                    {property.status && (
                      <span className="status">{property.status}</span>
                    )}
                  </PropertyCard>
                </Link>
              </Col>
            ))}
            <Col xs={24} sm={24} md={16} lg={16} xl={6} xxl={6}>
              {externalLink ? (
                <ExternalActionCard
                  href={externalLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  thumbnail={
                    properties[0] !== undefined
                      ? properties[0].thumb.length - 1 > 0
                        ? properties[0].thumb
                        : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
                      : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
                  }
                >
                  <div>
                    <div>{externalLink.label}</div>
                  </div>
                </ExternalActionCard>
              ) : internalLink ? (
                <InternalActionCard
                  to={internalLink.url}
                  thumbnail={
                    properties[0] !== undefined
                      ? properties[0].thumb.length - 1 > 0
                        ? properties[0].thumb
                        : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
                      : 'https://assets.primephilippines.com/images/project-listings/thumbnail/placeholder.jpg'
                  }
                >
                  <div>
                    <div>{internalLink.label}</div>
                  </div>
                </InternalActionCard>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}
ProjectPropertiesComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      propertyname: PropTypes.string,
      location: PropTypes.string,
      thumb: PropTypes.string,
      url_link: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  externalLink: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  internalLink: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  padding: PropTypes.string,
};

ProjectPropertiesComponent.defaultProps = {
  padding: '50px 0px',
  properties: [],
};

export default ProjectPropertiesComponent;
