import _ from 'lodash';

export function slugify(propertyName) {
  return propertyName
    .toString()
    .toLowerCase()
    .trim()
    .replace('#', '') // Replace # with empty string
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
    .replace(/-$/, ''); // Remove last floating dash if exists
}

export function sanitizePropertyName(propertyName) {
  return propertyName
    .toString()
    .replace(/- \(for demolition\)/gi, ``)
    .replace(/- TBD/gi, ``)
    .replace(/ - KMC Solutions/gi, ``)
    .replace(/- NOT YET OPERATIONAL/gi, ``)
    .replace(/- ON HOLD/gi, ``)
    .replace(/- FOR SALE/gi, ``)
    .replace(/- fully leased/gi, ``)
    .replace(/- fully-leased/gi, ``)
    .replace(/\(fully-leased\)/gi, ``)
    .replace(/\(fully leased\)/gi, ``)
    .replace(/- leased out/gi, ``)
    .replace(/\(Leased Out\)/gi, ``)
    .replace(/\(leased out\) -/gi, ``)
    .replace(/\(whole building only, POGO\)/gi, ``)
    .replace(/\(POGO back office\)/gi, ``)
    .replace(/\(6F - 14F POGO\)/gi, ``)
    .replace(/\(POGO HUB\) /gi, ``)
    .replace(/\(20% POGO\)/gi, ``)
    .replace(/\(7F - 12F Tower 2 POGO\)/gi, ``)
    .replace(/\(POGO back office\)/gi, ``)
    .replace(/- DO NOT OFFER/gi, ``)
    .replace(/\(incorrect #\)/gi, ``)
    .replace(/DO NOT OFFER-/gi, ``)
    .replace(/DO NOT OFFER - /gi, ``)
    .replace(/\(POGO\)/gi, ``)
    .replace(/\(POGO\) -/gi, ``)
    .replace(/\(Residential\)/gi, ``)
    .replace(/\(No longer available\)/gi, ``)
    .replace(/ \(used by management\)/gi, ``)
    .replace(/\(Under Renovation\)/gi, ``)
    .replace(/\(Not available\)/gi, ``)
    .replace(/\(Under auction\)/gi, ``)
    .replace(/\(Leticia Property\)/gi, ``)
    .replace(/\(Leoncio Property\)\)/gi, ``)
    .replace(/\(owned by Caritas Health Shield\)\)/gi, ``)
    .replace(/\*PBCom Tower/gi, `PBCom Tower`)
    .trim();
}

function authorityTypePosition(authorityType) {
  switch (authorityType) {
    case 'Exclusive':
      return 0;
    case 'Sole Agency':
      return 1;
    case 'Lead Broker':
      return 2;
    case 'Non-exclusive':
      return 3;
    case 'Open':
      return 4;
    case 'Soft':
      return 5;
    default:
      return 5;
  }
}

function mapListingsArr(arr) {
  return arr.map(node => ({
    array_type: '_listing',
    ...node,
    slug: `${slugify(sanitizePropertyName(node.property_name))}-${
      node.listing_id
    }`,
    property_name: sanitizePropertyName(node.property_name),
    address: `${node.location}${node.city ? `, ${node.city}` : ''}`,
    authorityPosition: authorityTypePosition(node.authority_type),
    images: node.listing_image,
    range: {
      minFloorArea: node.floor_area_float_val,
      maxFloorArea: node.floor_area_float_val,
      minLotArea: node.lot_area_float_val,
      maxLotArea: node.lot_area_float_val,
      minPrice: node.price_per_sqm_float_val,
      maxPrice: node.price_per_sqm_float_val,
    },
  }));
}

function mapProjectsArr(arr) {
  return arr.map(node => ({
    array_type: '_project',
    ...node,
    slug: `${slugify(sanitizePropertyName(node.project_name))}-${
      node.project_id
    }`,
    address: `${node.address}${node.city ? `, ${node.city}` : ''}`,
    authorityPosition: authorityTypePosition(node.authority_type),
    property_name: sanitizePropertyName(node.project_name),
    images: node.project_image,
    peza_accredited: node.office.data.peza_accredited,
    telecommunications_provider: node.office.data.telecommunications_provider,
    total_parking_slots: node.total_parking_lot,
    electrical_capacity: node.electric_capacity,
    range: {
      ...node.range,
      minLotArea: 0,
      maxLotArea: 0,
    },
  }));
}

/**
 *  @param {Object} searchParams filters of listings
 */
export function filterAllListings(data, searchParams = null) {
  const allPropertiesCommercialLots = mapListingsArr(
    data.allPropertiesCommercialLots.nodes.filter(
      listing => listing.property_name !== null
    )
  );
  const allPropertiesIndustrialLots = mapListingsArr(
    data.allPropertiesIndustrialLots.nodes.filter(
      listing => listing.property_name !== null
    )
  );
  const allPropertiesWarehouses = mapListingsArr(
    data.allPropertiesWarehouses.nodes.filter(
      listing => listing.property_name !== null
    )
  );
  const allPropertiesRetailProjects = mapProjectsArr(
    data.allPropertiesRetailProjects.nodes.filter(
      listing => listing.property_name !== null
    )
  );
  const allPropertiesOfficeProjects = mapProjectsArr(
    data.allPropertiesOfficeProjects.nodes.filter(
      listing => listing.property_name !== null
    )
  );

  let allListings = [
    ...allPropertiesCommercialLots,
    ...allPropertiesIndustrialLots,
    ...allPropertiesWarehouses,
    ...allPropertiesRetailProjects,
    ...allPropertiesOfficeProjects,
  ];

  if (searchParams) {
    // filter by property_type
    allListings = allListings
      .filter(listing => listing.property_type !== null)
      .filter(listing => {
        if (searchParams.propertyType === 'Office') {
          return listing.property_type.includes('Office');
        }

        if (searchParams.propertyType === 'Retail') {
          return (
            listing.property_type.includes('CL') ||
            listing.property_type.includes('Retail')
          );
        }

        if (searchParams.propertyType === 'Industrial') {
          return (
            listing.property_type.includes('IL') ||
            listing.property_type.includes('IW')
          );
        }

        return listing;
      });

    // filter by location
    allListings = allListings.filter(
      listing =>
        listing.address
          .toLowerCase()
          .includes(searchParams.location.toLowerCase()) ||
        listing.property_name
          .toLowerCase()
          .includes(searchParams.location.toLowerCase())
    );

    // filter by size range
    // check minLotArea,maxLotArea,minFloorArea,maxFloorArea
    // if the property size is in range of sizeRange
    allListings = allListings.filter(
      listing =>
        (_.inRange(
          listing.range.minLotArea,
          searchParams.sizeRange[0],
          searchParams.sizeRange[1]
        ) &&
          _.inRange(
            listing.range.maxLotArea,
            searchParams.sizeRange[0],
            searchParams.sizeRange[1]
          )) ||
        (_.inRange(
          listing.range.minFloorArea,
          searchParams.sizeRange[0],
          searchParams.sizeRange[1]
        ) &&
          _.inRange(
            listing.range.maxFloorArea,
            searchParams.sizeRange[0],
            searchParams.sizeRange[1]
          ))
    );

    // filter by price range
    // check minPrice,maxPrice
    // if the property price is in range of priceRange
    allListings = allListings.filter(
      listing =>
        _.inRange(
          listing.range.minPrice,
          searchParams.priceRange[0],
          searchParams.priceRange[1]
        ) &&
        _.inRange(
          listing.range.maxPrice,
          searchParams.priceRange[0],
          searchParams.priceRange[1]
        )
    );

    // additional filters if property type is `Office`
    if (searchParams.propertyType === 'Office') {
      // filter by selected accreditation
      allListings = allListings.filter(listing =>
        searchParams.office.accreditations.includes('PEZA')
          ? listing.peza_accredited === 'Yes'
          : true
      );

      if (searchParams.office.telcoProviders.length > 0) {
        allListings = allListings.filter(
          listing =>
            searchParams.office.telcoProviders.filter(telco =>
              listing.telecommunications_provider
                .toLowerCase()
                .includes(telco.toLowerCase())
            ).length > 0
        );
      }

      if (searchParams.office.turnoverConditions.length > 0) {
        allListings = allListings.filter(
          listing =>
            searchParams.office.turnoverConditions.filter(handover => {
              if (handover.toLowerCase() === 'bare shell') {
                return listing.handover_condition
                  .toLowerCase()
                  .includes('bare');
              }
              return listing.handover_condition
                .toLowerCase()
                .includes(handover.toLowerCase());
            }).length > 0
        );
      }
    }

    // additional filter if property type if `Retail`
    if (searchParams.propertyType === 'Retail') {
      if (searchParams.retail.developmentType !== 'All') {
        allListings = allListings.filter(listing =>
          listing.property_type
            .toLowerCase()
            .includes(
              `${
                searchParams.retail.developmentType === 'Commercial Lots'
                  ? 'CL'
                  : 'Retail'
              }`.toLowerCase()
            )
        );
      }

      if (searchParams.retail.turnoverConditions.length > 0) {
        allListings = allListings.filter(
          listing =>
            searchParams.retail.turnoverConditions.filter(handover => {
              if (handover.toLowerCase() === 'bare shell') {
                return listing.handover_condition
                  .toLowerCase()
                  .includes('bare');
              }
              return listing.handover_condition
                .toLowerCase()
                .includes(handover.toLowerCase());
            }).length > 0
        );
      }

      allListings = allListings.filter(listing =>
        listing.availability
          .toLowerCase()
          .includes(searchParams.retail.readyForOccupancy ? 'rfo' : '')
      );
      if (searchParams.retail.parkingSlots)
        allListings = allListings.filter(
          listing => listing.total_parking_slots !== ''
        );
    }

    // additional filter if property type if `Industrial`
    if (searchParams.propertyType === 'Industrial') {
      // filters listings by electric load
      if (searchParams.industrial.electricLoad !== 'All') {
        allListings = allListings.filter(listing => {
          if (searchParams.industrial.electricLoad === 'Single-Phase') {
            return (
              listing.electrical_capacity.toLowerCase().includes(`1-phase`) ||
              listing.electrical_capacity.toLowerCase().includes(`single-phase`)
            );
          }

          if (searchParams.industrial.electricLoad === 'Three-Phase') {
            return (
              listing.electrical_capacity.toLowerCase().includes(`3-phase`) ||
              listing.electrical_capacity.toLowerCase().includes(`three-phase`)
            );
          }
          return true;
        });
      }

      // exclude listings matched with tenant restrictions
      if (searchParams.industrial.tenantRestrictions.length > 0) {
        allListings = allListings.filter(
          listing =>
            searchParams.industrial.tenantRestrictions.filter(
              tenantRestriction =>
                !listing.tenant_restrictions
                  .toLowerCase()
                  .includes(tenantRestriction.toLowerCase())
            ).length > 0
        );
      }
    }
  }

  const filteredListings = allListings
    .sort((a, b) => a.authorityPosition - b.authorityPosition)
    .reduce((accumulator, curVal) => {
      if (
        accumulator.find(element => {
          if (curVal.array_type === '_listing')
            return element.listing_code === curVal.listing_code;
          if (curVal.array_type === '_project')
            return element.project_code === curVal.project_code;
          return true;
        })
      ) {
        return accumulator;
      }
      return [...accumulator, curVal];
    }, []);

  return filteredListings;
}
