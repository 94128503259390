import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';

const StyledDivWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .list-prop-wrapper {
    padding: 30px 0px;
    position: relative;
    background-color: ${props => `${props.theme.primeBluePalette.original}BB`};
    color: white;
    overflow: hidden;
    h4 {
      font-size: 1.5em;
      margin: 0;
    }

    :after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../../images/home/find-property-bg.jpg');
    }

    .btn-view-sched {
      color: ${props => props.theme.primeBluePalette.original};
      background: ${props => props.theme.primeYellowPalette.original};
      border-color: ${props => props.theme.primeYellowPalette.original};
    }

    .list-prop-cta,
    .list-prop-desc {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .list-prop-desc {
      justify-content: flex-start;
      @media only screen and (max-width: 576px) {
        justify-content: center;

        text-align: center;
        h4 {
          margin-bottom: 14px;
        }
      }
    }
  }
`;

function ListYourPropertyComponent() {
  return (
    <StyledDivWrapper>
      <div className="list-your-property">
        <Row className="list-prop-wrapper" type="flex" justify="center">
          <Col xs={22} sm={23} md={22} lg={20} xl={20} xxl={20}>
            <Row type="flex" justify="center">
              <Col
                className="list-prop-desc"
                xs={24}
                sm={24}
                md={16}
                lg={18}
                xl={18}
                xxl={14}
              >
                <h4>Let our dedicated teams take care of your property.</h4>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                xxl={6}
                className="list-prop-cta"
              >
                <br />
                <Button
                  className="btn-view-sched"
                  size="medium"
                  href="/contact-us"
                >
                  <b>LIST YOUR PROPERTY</b>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </StyledDivWrapper>
  );
}

export default ListYourPropertyComponent;
